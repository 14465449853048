import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SendSearch = _resolveComponent("SendSearch")!
  const _component_SendResult = _resolveComponent("SendResult")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SendSearch, {
      keyword: _ctx.keyword,
      "onUpdate:keyword": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.keyword) = $event)),
      status: _ctx.status,
      "onUpdate:status": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.status) = $event)),
      onSearch: _ctx.pushState
    }, null, 8, ["keyword", "status", "onSearch"]),
    _createVNode(_component_SendResult, { sendList: _ctx.result }, null, 8, ["sendList"])
  ], 64))
}