
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import OuterList from '@/components/organisms/dms/OuterList/OuterList.vue'
export default defineComponent({
  components: {
    OuterList,
  },
  setup () {
    const router = useRouter()
    return {
      router,
    }
  },
})
