
import { defineComponent, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import DssIcon from '@/components/atoms/DssIcon.vue'
import PageNavigation from '@/components/molecules/PageNavigation.vue'
import PlaceholderImage from '@/components/atoms/PlaceholderImage.vue'
import { convertStatus, convertPermission } from '@/helpers/util/dmsUtils'

export default defineComponent({
  name: 'SendItemSearch',
  components: {
    DssIcon,
    PageNavigation,
    PlaceholderImage,
  },
  props: {
    sendList: {
      type: Object,
    },
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const pageNumber = computed({
      get: () => Number(route.query.page ? route.query.page : 0),
      set: (val) => {
        router.push({
          query: {
            ...route.query,
            page: (val).toString(),
          },
        })
      },
    })

    const pageNavigationIndex = computed(() => {
      const pageCount = Math.ceil(props.sendList ? props.sendList.count / props.sendList.perpage : 0)
      return Array(pageCount).fill(0).map((_, i) => (i + 1).toString())
    })

    const convertDate = (date: string) => {
      return date ? date.replace('/', '年')
        .replace('/', '月')
        .replace(' ', '日 ')
        .replace(':', '時')
        .split(':')[0] + '分' : '-'
    }

    const convertNull = (data: any) => {
      return data || '-'
    }

    const splitDate = (date: string) => {
      return date ? date.split('.')[0] : '-'
    }

    const formatJst = (dateJst: string) => {
      if (dateJst && dateJst.includes('T') && dateJst.includes('+09:00')) {
        return dateJst.replace('T', ' ').replace('+09:00', '').split('.')[0]
      }
      return '-'
    }

    const toItem = async (pid: string) => {
      const pidNum = pid.split('/')[2]
      await router.push(`/dms/outer/item/${pidNum}`)
    }

    return {
      props,
      router,
      pageNumber,
      pageNavigationIndex,
      convertStatus,
      convertPermission,
      splitDate,
      convertDate,
      convertNull,
      formatJst,
      toItem,
    }
  },
})
