
import { defineComponent, computed } from 'vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import AppSelect from '@/components/atoms/AppSelect.vue'

export default defineComponent({
  name: 'SendSearch',
  components: {
    DssIcon,
    AppInput,
    AppSelect,
  },
  props: {
    keyword: {
      type: String,
    },
    status: {
      type: String,
    },
  },
  setup (props, context) {
    const keywordValue = computed({
      get: () => props.keyword,
      set: (keywordValue) =>
        context.emit('update:keyword', keywordValue),
    })
    const selectedStatus = computed({
      get: () => props.status,
      set: (selectedStatus) =>
        context.emit('update:status', selectedStatus),
    })
    const statusList = [
      { name: 'すべての処理結果', value: '' },
      { name: '確認事項あり', value: 'refusal' },
      { name: '処理中', value: 'processing' },
      { name: '終了', value: 'successful' },
    ]

    return {
      context,
      selectedStatus,
      keywordValue,
      statusList,
    }
  },
})
