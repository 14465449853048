
import { defineComponent, ref, onMounted } from 'vue'
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
import SendSearch from '@/components/organisms/dms/OuterList/SendSearch.vue'
import SendResult from '@/components/organisms/dms/OuterList/SendResult.vue'
import { getDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OuterList',
  components: {
    SendSearch,
    SendResult,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const keyword = route.query.keyword ? ref(route.query.keyword) : ref('')
    const status = route.query.status ? ref(route.query.status) : ref('')
    const page = route.query.page ? ref(Number(route.query.page)) : ref(0)
    const result = ref()

    const getSendList = async () => {
      const url = `${BASE_URL}/outer/item/sent/history?keyword=${keyword.value}&status=${status.value}&page=${page.value + 1}&perpage=100`
      const response = await getDataCredential(url)
      result.value = response.data
    }

    const pushState = () => {
      router.push({
        query: {
          keyword: keyword.value,
          status: status.value,
          page: route.query.page ? route.query.page : 0,
        },
      })
    }

    onBeforeRouteUpdate(async (to, _) => {
      page.value = Number(to.query.page) || 0
      await getSendList()
    })
    onMounted(async () => {
      await getSendList()
    })

    return {
      result,
      keyword,
      status,
      pushState,
    }
  },
})
