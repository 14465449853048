
import { defineComponent, computed, PropType } from 'vue'
import AppButton from '../atoms/AppButton.vue'
import ButtonIcon from '../molecules/ButtonIcon.vue'

export default defineComponent({
  name: 'PageNavigation',
  components: {
    AppButton,
    ButtonIcon,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array as PropType<string[]>,
      required: true,
    },
    displayCount: {
      type: Number,
      default: 5,
    },
  },
  emits: ['update:modelValue', 'input'],
  setup (props, context) {
    const index = computed({
      get: () => props.modelValue,
      set: (val) => {
        val = Math.max(0, val)
        val = Math.min(props.items.length - 1, val)
        context.emit('update:modelValue', val)
        context.emit('input', val)
      },
    })

    const displayCount = computed(
      () => Math.min(props.displayCount, props.items.length)
    )

    const start = computed(() => {
      const val = Math.max(
        0,
        index.value - Math.floor((displayCount.value / 2))
      )
      if (val === 1) {
        return 0
      } else {
        return Math.min(props.items.length - displayCount.value, val)
      }
    })

    const end = computed(() => {
      const val = Math.min(
        props.items.length,
        index.value + Math.floor((displayCount.value / 2)) + 1
      )
      if (val === props.items.length - 1) {
        return props.items.length
      } else {
        return Math.max(start.value + displayCount.value, val)
      }
    })

    const lineup = computed(() => {
      return props.items.slice(start.value, end.value)
    })

    return {
      index,
      lineup,
      start,
      end,
    }
  },
})
